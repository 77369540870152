import { FC, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useActivites } from '../../contexts/ActivitiesContext';
import usePermissions from '../../hooks/permissions/usePermissions';
import { FormStatusTag } from '../../models/ClientFormStatus';
import { FormListItem } from '../../models/Form';
import { Roles } from '../../models/Role';
import { currentUserAtom } from '../../recoil/atoms/Auth';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import ClientFormService from '../../services/ClientFormService';
import { dataAttributeProps, mouseAndKeyboardCallbackProps } from '../../utils/ComponentUtils';
import FormUtils from '../../utils/FormUtils';
import LanguageUtils from '../../utils/LanguageUtils';
import { clientFormRoute, goToClientForm } from '../../utils/NavigationUtils';
import RiskUtils from '../../utils/RiskUtils';
import ActivityIcon from '../form/ActivityIcon';
import FormOwnership from '../ownership/FormOwnership';
import { OwnershipDisplayType } from '../ownership/OwnershipProps';
import DataRow from '../shared/data-grid/DataRow';
import DueDatePicker from '../shared/DueDatePicker';
import FlagIcon from '../shared/icon/FlagIcon';
import ProgressBar from '../shared/ProgressBar';
import Tooltip from '../shared/Tooltip';
import Checkbox from '../shared/form-control/Checkbox';
import FormInfoModal from '../form/FormInfoModal';
import { useItemSelection } from '../../contexts/select-items/SelectItemsContext';
import { DocumentListItem } from '../../models/Document';
import { FormType } from '../../models/FormTypes';
import useFetchFormUsers from '../../hooks/useFetchFormUsers';
import DistributionStatusTag from '../distribution/DistributionStatusTag';

type ActivityRowProps = {
  activity: FormListItem;
  selected: boolean;
  onSelected: (value: boolean) => void;
  onDownload?: (activityId: string) => void;
  onArchive?: (activity: FormListItem) => void;
  onRestore?: (activityId: string) => void;
};

const ActivityRow: FC<ActivityRowProps> = (props) => {
  const { t } = useTranslation(['task-list', 'common', 'module', 'assets', 'risk']);
  const { activity, onDownload, onArchive, onRestore, selected, onSelected } = props;
  const { hasContextMenu } = useActivites();
  const { selection } = useItemSelection();
  const currentClient = useRecoilValue(currentClientAtom);
  const currentUser = useRecoilValue(currentUserAtom);
  const isSharedReceiver = activity.isShared && activity?.clientId !== currentClient?.id;
  const [dueDateUtc, setDueDate] = useState<Date | null>(activity.dueDateUtc ? new Date(activity.dueDateUtc) : null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const navigate = useNavigate();
  const hasPermission = usePermissions();

  const onDueDateChange = (date: Date | null) => {
    setDueDate(date);
    ClientFormService.updateForm(
      activity.id,
      activity.accessType,
      date,
      activity.effectiveDateUtc ? new Date(activity.effectiveDateUtc) : null,
      activity.subtitle,
      activity.clientModuleId,
      activity.clientModuleSectionId,
    );
  };

  const { data: users = [] } = useFetchFormUsers(activity.id, activity.users, activity.isShared);

  const activityTitle = useCallback(
    (truncate: boolean) => {
      const mainText =
        activity.type === FormType.Document
          ? FormUtils.getDocumentTitle(activity as DocumentListItem)
          : activity.subtitle || LanguageUtils.getTranslation('title', activity.translations);
      const subText =
        activity.type === FormType.Document && activity.isSystemTemplateForm
          ? LanguageUtils.getTranslation('name', activity.templateModuleTranslations)
          : LanguageUtils.getTranslation('title', activity.translations);

      return (
        <div className={`${truncate ? 'truncate' : ''}`}>
          <div className={`${truncate ? 'truncate' : ''}`}>
            {mainText} {FormUtils.formHostName(activity, currentClient?.id || '', ' ({client})')}
          </div>
          {mainText !== subText && <div className="text-color-3 text-dpm-12 truncate">{subText}</div>}
        </div>
      );
    },
    [activity, currentClient?.id],
  );

  const isShared = useCallback(
    (formClientId: string) => {
      return formClientId !== currentClient?.id;
    },
    [currentClient?.id],
  );

  const onPreviewClick = (form: FormListItem): void => {
    goToClientForm(form, currentUser, navigate, true);
  };

  return (
    <DataRow
      {...dataAttributeProps(props)}
      url={clientFormRoute(activity, currentUser)}
      contextMenuItems={[
        {
          title: t('assets:list.context-menu.details'),
          onClick: () => setShowDetailsModal(true),
          hide: !hasContextMenu,
        },
        {
          title: t('assets:list.context-menu.preview'),
          onClick: () => onPreviewClick(activity),
          hide: !hasContextMenu || isShared(activity.clientId),
        },
        {
          title: t('common:list.context-menu.archive'),
          onClick: () => {
            onArchive && onArchive(activity);
          },
          hide: !hasContextMenu || !hasPermission(Roles.TeamMember) || isShared(activity.clientId) || activity.archivedUtc,
        },
        {
          title: t('module:list.context-menu.restore'),
          onClick: () => onRestore && onRestore(activity.id),
          hide: !hasContextMenu || isSharedReceiver || !activity.archivedUtc,
        },
        {
          title: t('assets:list.context-menu.export'),
          onClick: () => {
            onDownload && onDownload(activity.id);
          },
          hide: !hasContextMenu || (!onDownload && hasPermission(Roles.ExternalAuditor) && !hasPermission(Roles.Management)),
        },
      ]}
    >
      <div className="flex flex-grow items-center text-left">
        {!selection.isDisabled && (
          <div className="w-10">
            <Checkbox value={selected} onChange={onSelected} />
          </div>
        )}
        <div className="flex w-12 items-center justify-center">
          <ActivityIcon form={activity} />
        </div>
        <div className="w-full pr-4">
          <Tooltip text={activityTitle(false)} truncatedTextMode>
            {(tooltip) => (
              <div className="flex min-w-full max-w-0 items-center truncate font-medium text-black" {...tooltip}>
                {activityTitle(true)} {activity.distribution && <DistributionStatusTag distribution={activity.distribution} />}
              </div>
            )}
          </Tooltip>
        </div>
      </div>
      <div className="w-56" {...mouseAndKeyboardCallbackProps((e) => e.stopPropagation())}>
        <DueDatePicker
          clientFormStatus={activity.status}
          value={dueDateUtc && new Date(dueDateUtc)}
          onChange={onDueDateChange}
          disabled={isSharedReceiver || (!hasPermission([Roles.TeamMember]) && !!currentUser)}
        />
      </div>
      <div className="w-32">
        <FormOwnership users={users} associatedId={activity.id} size={OwnershipDisplayType.Tiny} viewOnly displayLimit={3} />
      </div>
      <div className="w-48">
        <FormStatusTag activity={activity} />
      </div>
      <div className="w-40 pr-6">
        {activity.type === FormType.Document && activity.isSystemTemplateForm ? (
          <Tooltip text={t('common:forms.no-progress-calculated')}>
            {(tooltip) => (
              <span {...tooltip}>
                <ProgressBar progress={0} />
              </span>
            )}
          </Tooltip>
        ) : (
          <ProgressBar progress={activity.progress} />
        )}
      </div>
      <div className="w-10">
        <Tooltip text={RiskUtils.textFor(t, activity)}>
          {(tooltip) => (
            <div {...tooltip}>
              <FlagIcon className={`h-6 w-6 ${RiskUtils.textColorFor(activity.totalRiskScore) || 'text-gray-300'}`} />
            </div>
          )}
        </Tooltip>
      </div>

      <FormInfoModal open={showDetailsModal} onClose={() => setShowDetailsModal(false)} form={activity} clientFormUsers={users} />
    </DataRow>
  );
};

export default ActivityRow;
